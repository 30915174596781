import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import AddNewCode from "../AddNewCode/AddNewCode";
import EditCode from "../EditCode/EditCode";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

function CrudsModal({ open, handleClose, type, currentCode }) {
	return (
		<div>
			<Button onClick={handleClose}>Close</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					{type?.toLowerCase() === "add" ? (
						<AddNewCode />
					) : (
						<EditCode currentCode={currentCode} handleClose={handleClose}/>
					)}
				</Box>
			</Modal>
		</div>
	);
}

export default CrudsModal;
