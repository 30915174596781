import './App.css'
import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Box } from "@mui/material";
import PrivateRoutes from "./utils/PrivateRoutes";
import PublicRoutes from "./utils/PublicRoutes";


export default function App() {
  let accessToken = sessionStorage.getItem('accessToken');

  // For future use when the login end point is done to seprate private routes from public ones
  const router = createBrowserRouter([
    accessToken !== null ? PrivateRoutes() : {}, ...PublicRoutes()
  ])

  // all routes are available now 
  // const router = createBrowserRouter([
  //   PrivateRoutes(), ...PublicRoutes()
  // ])

  return (
    <Box className="app">
      <RouterProvider router={router} />
    </Box>
  );
}
