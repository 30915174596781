import { doc, deleteDoc } from "firebase/firestore";
import { db } from '../firebase'
import Swal from "sweetalert2";

const deleteCode = async (id) => {

  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!"
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        await deleteDoc(doc(db, "epicshot", id)).then(() => {
          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success"
          }).then(() => {
            window.location.reload(true)
          })
        })
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: `${error}`,
          icon: "error"
        })
      }
    }
  });
  console.log(id, 'deleted')
}
export default deleteCode