import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { Button, TableHead, Typography } from "@mui/material";
import CrudsModal from "../CrudsModal/CrudsModal";
import Header from "../Header/Header";
import deleteCode from "../../utils/deleteCode";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

function Dashboard() {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [codes, setCodes] = React.useState();
	const [open, setOpen] = React.useState();
	const [crudActionType, setCrudActionType] = React.useState();
	const [currentCode, setCurrentCode] = React.useState({});

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - codes?.length) : 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const getCodes = async () => {
		const querySnapshot = await getDocs(collection(db, "epicshot"));
		const data = querySnapshot.docs.map((doc, index) => ({
			id: doc.id,
			...doc.data(),
		}));
		setCodes(data);
	};

	React.useEffect(() => {
		getCodes();
	}, []);

	console.log(codes);

	return (
		<Box
			sx={{
				height: "100%",
			}}
		>
			<Header />

			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-around",
					alignItems: "center",
					height: "80%",
					width: "100vw",
				}}
			>
				<Typography
					component={"h2"}
					sx={{
						fontSize: "36px",
						fontWeight: "bold",
					}}
				>
					Epic-shots Codes
				</Typography>
				<Button
					variant="contained"
					color="success"
					sx={{
						textTransform: "capitalize",
						ml: 1,
					}}
					onClick={() => {
						setCrudActionType("add");
						setOpen(true);
					}}
				>
					<AddIcon />
					Add New
				</Button>
				{open && (
					<CrudsModal
						handleClose={() => setOpen(false)}
						open={open}
						type={crudActionType}
						currentCode={currentCode}
					/>
				)}
				<TableContainer
					component={Paper}
					sx={{
						maxWidth: 1600,
					}}
				>
					<Table sx={{ minWidth: 900 }} aria-label="custom pagination table">
						<TableHead>
							<TableRow>
								<TableCell component="td" scope="row">
									ID
								</TableCell>
								<TableCell component="td" scope="row">
									Code
								</TableCell>
								<TableCell style={{ width: 160 }} align="left">
									URL
								</TableCell>
								<TableCell style={{ width: 160 }} align="left">
									Actions
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{codes?.map((code) => (
								<TableRow key={code.id}>
									<TableCell component="td" scope="row">
										{code.id}
									</TableCell>
									<TableCell component="td" scope="row">
										{code.code}
									</TableCell>
									<TableCell style={{ width: 160 }} align="left">
										{code.url}
									</TableCell>
									<TableCell
										sx={{
											minWidth: 200,
										}}
									>
										<Button
											variant="contained"
											sx={{
												textTransform: "capitalize",
												ml: 1,
											}}
											onClick={() => {
												setCurrentCode(code);
												setCrudActionType("edit");
												setOpen(true);
											}}
										>
											<EditIcon />
											Edit
										</Button>
										<Button
											variant="contained"
											color="error"
											sx={{
												textTransform: "capitalize",
												ml: 1,
											}}
											onClick={(e) => {
												deleteCode(code.id);
											}}
										>
											<DeleteForeverIcon />
											Delete
										</Button>
									</TableCell>
								</TableRow>
							))}
							{emptyRows > 0 && (
								<TableRow style={{ height: 53 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
						<TableFooter>
							<TableRow>
								<TablePagination
									rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
									colSpan={3}
									count={codes?.length}
									rowsPerPage={rowsPerPage}
									page={page}
									SelectProps={{
										inputProps: {
											"aria-label": "rows per page",
										},
										native: true,
									}}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
									ActionsComponent={TablePaginationActions}
								/>
							</TableRow>
						</TableFooter>
					</Table>
				</TableContainer>
			</Box>
		</Box>
	);
}

export default Dashboard;
