import { Box, Button, TextField } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import Swal from "sweetalert2";

const EditCode = ({ currentCode, handleClose }) => {
	const [id, setId] = useState("");
	const [code, setCode] = useState("");
	const [url, setUrl] = useState("");

	const editCurrentRecord = async (e) => {
		e.preventDefault();
		try {
			await setDoc(doc(db, "epicshot", id), {
				code,
				url,
			}).then(() => {
				handleClose();
				Swal.fire({
					position: "center",
					icon: "success",
					title: "The code has been updated",
					showConfirmButton: false,
					timer: 2500,
				}).then(() => {
					window.location.reload(true);
				});
			});
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		setId(currentCode.id);
		setCode(currentCode.code);
		setUrl(currentCode.url);
	}, [currentCode]);

	console.log(id, code, url);
	return (
		<Box
			component="form"
			onSubmit={(e) => {
				editCurrentRecord(e);
			}}
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<TextField
				type="text"
				helperText="Enter customer unique code"
				value={code}
				onChange={(e) => setCode(e.target.value)}
			/>
			<TextField
				type="text"
				helperText="Enter customer media link"
				value={url}
				onChange={(e) => setUrl(e.target.value)}
			/>
			<Button type="submit" variant="contained">
				Edit
			</Button>
		</Box>
	);
};

export default EditCode;
