import { Box, Button } from "@mui/material";
import { NavLink } from "react-router-dom";

const Error404 = () => {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				flexDirection: "column",
			}}
		>
			<Box component="h2">404 - Not Found!</Box>
			<Button
				variant="contained"
				sx={{
					p: 0,
				}}
			>
				<NavLink
					to="/"
					style={{
						color: "#fff",
						textDecoration: "none",
						textTransform: "capitalize",
						width: "100%",
					}}
				>
					Home
				</NavLink>
			</Button>
		</Box>
	);
};

export default Error404;
