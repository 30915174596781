import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import LogoBlack from "../../assets/epicshot-black.png";
import { NavLink } from "react-router-dom";

const defaultTheme = createTheme();

export default function SignIn() {
	let accessToken = sessionStorage.getItem("accessToken");

	const handleSubmit = async (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		try {
			await signInWithEmailAndPassword(
				auth,
				data.get("email"),
				data.get("password")
			).then((userCredentials) => {
				sessionStorage.setItem("accessToken", userCredentials.user.accessToken);
				window.location.assign("/dashboard");
				console.log(userCredentials.user);
			});
		} catch (error) {
			console.log(error);
		}
	};

	return accessToken === null ? (
		<ThemeProvider theme={defaultTheme}>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Box component={"img"} src={LogoBlack} sx={{ width: "200px" }} />
					<Typography component="h1" variant="h5">
						Sign in
					</Typography>
					<Box
						component="form"
						onSubmit={handleSubmit}
						noValidate
						sx={{ mt: 1 }}
					>
						<TextField
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							autoComplete="email"
							autoFocus
							type="email"
						/>
						<TextField
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							id="password"
							autoComplete="current-password"
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
						>
							Sign In
						</Button>
					</Box>
				</Box>
			</Container>
		</ThemeProvider>
	) : (
		<Box>
			<Box component="h2">You've logged in already</Box>
			<Box sx={{ display: "flex", justifyContent: "space-between" }}>
				<Typography>Go to</Typography>
				<Button
					variant="contained"
					sx={{
						p: 0,
					}}
				>
					<NavLink
						to="/dashboard"
						style={{
							color: "#fff",
							textDecoration: "none",
							textTransform: "capitalize",
							padding: '4px 16px'
						}}
					>
						Dashboard
					</NavLink>
				</Button>
				<Typography>Or</Typography>
				<Button
					variant="contained"
					sx={{
						p: 0,
					}}
				>
					<NavLink
						to="/"
						style={{
							color: "#fff",
							textDecoration: "none",
							textTransform: "capitalize",
							padding: '4px 16px'
						}}
					>
						Home
					</NavLink>
				</Button>
			</Box>
		</Box>
	);
}
