import { Box, Button, TextField } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useState } from "react";

const AddNewCode = () => {
	const [code, setCode] = useState("");
	const [url, setUrl] = useState("");
	const addNewRecord = async (e) => {
		e.preventDefault();
		try {
			await setDoc(doc(db, "epicshot", code), {
				code,
				url,
			});
			window.location.reload(true);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Box
			component="form"
			onSubmit={(e) => {
				addNewRecord(e);
			}}
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<TextField
				type="text"
				label="Code"
				helperText="Enter customer unique code"
				onChange={(e) => setCode(e.target.value)}
			/>
			<TextField
				type="text"
				label="URL"
				helperText="Enter customer media link"
				onChange={(e) => setUrl(e.target.value)}
			/>
			<Button type="submit" variant="contained">
				Add
			</Button>
		</Box>
	);
};

export default AddNewCode;
