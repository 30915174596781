import { Navigate } from "react-router-dom";
import Error404 from "../components/404/Error404";
import Home from "../components/Home/Home";
import SignIn from "../components/SignIn/SignIn";

export default function PublicRoutes() {
  return [
    {
      path: "/", element: <Home />
    },
    {
      path: "/404", element: <Error404 />
    },
    { path: "/adminlogin", element: <SignIn /> },

    { path: "*", element: <Navigate to="/404" replace /> },
  ];
}