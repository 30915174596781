import Dashboard from "../components/Dashboard/Dashboard";
import Layout from "../components/Layout/Layout";

const PrivateRoutes = () => {
  return {
    element: <Layout />,
    children: [
      { path: "/dashboard", element: <Dashboard /> },
    ],
  };
};

export default PrivateRoutes;