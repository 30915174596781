import "./Home.css";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import LogoBlack from "../../assets/epicshot-black.png";
import { getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { collection, query, where } from "firebase/firestore";

const Home = () => {
	const [code, setCode] = useState("");
	const [validCode, setValidCode] = useState(false);
	const [unValidCode, setUnValidCode] = useState(false);
	const [mediaUrl, setMediaUrl] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const getCode = async (e) => {
		e.preventDefault();
		if (code.length > 0) {
			try {
				// Create a reference to the cities collection
				const epicShotRef = collection(db, "epicshot");

				// Create a query against the collection.
				const q = query(epicShotRef, where("code", "==", code));
				const docSnap = await getDocs(q);

				if (docSnap?.docs?.length > 0) {
					setValidCode(true);
					setUnValidCode(false);
					setMediaUrl(docSnap?.docs[0]?.data()?.url);
					setErrorMessage("");
				} else {
					setValidCode(false);
					setUnValidCode(true);
					// setErrorMessage(
					// 	`${(
					// 		<Box>
					// 			<Typography>Dear guest,</Typography>
					// 			<Typography>
					// 				Your media is not available yet, please check again soon,,
					// 			</Typography>
					// 			<Typography>Epic shot team.</Typography>
					// 			<Typography>
					// 				For any Enquiries feel free to contact us through our email
					// 			</Typography>
					// 			<Typography>support@epic-shot.net</Typography>
					// 		</Box>
					// 	)}`
					// );
					setErrorMessage(true);
				}
			} catch (error) {
				setValidCode(false);
				setUnValidCode(true);
				setErrorMessage("Something went wrong!");
			}
		} else {
			setValidCode(false);
			setUnValidCode(true);
			setErrorMessage("Please enter a valid code!");
		}
	};

	return (
		<Box className="flex-container code-background">
			<Box className="cover"></Box>
			<Box
				sx={{
					zIndex: 9999,
				}}
			>
				<Box
					component={"img"}
					src={LogoBlack}
					sx={{
						width: "200px",
					}}
				/>
			</Box>
			<Box
				component="form"
				className="code-container"
				onSubmit={(e) => getCode(e)}
				sx={{
					zIndex: 999,
				}}
			>
				<Typography
					component={"p"}
					sx={{
						fontSize: "18px",
					}}
				>
					Enter your code and click on submit to get your happy moments
				</Typography>
				<TextField
					label="Code"
					helperText="Enter your code and click the button below"
					value={code}
					onChange={(e) => setCode(e.target.value)}
					sx={{
						background: "#fff",
					}}
				/>
				<Button
					variant="contained"
					type="submit"
					sx={{
						background: "#000",
						"&:hover": {
							background: "#000",
							opacity: 0.9,
						},
					}}
				>
					Submit
				</Button>
			</Box>
			{validCode && (
				<Box className="code-container">
					<Typography
						component="h2"
						sx={{
							fontSize: "32px",
							zIndex: 999,
						}}
					>
						Thanks for being our guest
					</Typography>
					<Button
						variant="contained"
						component="a"
						target="_blank"
						rel="noreferrer"
						href={mediaUrl}
						sx={{
							textTransform: "none",
						}}
					>
						Download your happy moments
					</Button>
				</Box>
			)}
			{unValidCode && (
				<Typography
					sx={{
						color: "red",
						fontSize: "16px",
						zIndex: 999,
						maxWidth: 500,
						p: 2,
					}}
				>
					{errorMessage === true ? (
						<Box>
							<Typography>Dear guest,</Typography>
							<Typography>
								Your media is not available yet, please check again soon,
							</Typography>
							<Typography>Epic shot team.</Typography>
							<Typography>
								For any Enquiries feel free to contact us through our email
							</Typography>
							<Box component="a" href="mailto:support@epic-shot.net">
								support@epic-shot.net
							</Box>
						</Box>
					) : (
						errorMessage
					)}
				</Typography>
			)}
		</Box>
	);
};

export default Home;
