// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

//  apiKey: "AIzaSyAocXBJnzY6CBM5n0RHoFOX6sL8gj0DMEA",
//  authDomain: "epicshot-517b6.firebaseapp.com",
//   projectId: "epicshot-517b6",
//  storageBucket: "epicshot-517b6.appspot.com",
//   messagingSenderId: "157100737237",
//   appId: "1:157100737237:web:4d955e06411dd3adbd985c",
//   measurementId: "G-Z0NN4FHLPF"

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
export const auth = getAuth(app);

